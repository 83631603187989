
import React, { Suspense, useState, useEffect } from 'react';
import {Routes,  Route } from "react-router-dom";
import TrackShipment from "./component/TrackShipment";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<TrackShipment />}></Route>
      </Routes>
    </div>
  );
}

export default App;