import axios from 'axios';

const baseString = 'b2cfeee7-f777-4855-9e17-b0cd637448ce';

const api = axios.create({
    baseURL: 'https://connect.gaintlogistic.com/v1',
    // timeout: 120000,
    headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
    },
});

// Define your common API function
const commonApi = {
    get: (url, params = {}) => {
        return api.get(url, { params });
    },
    post: (url, data = {}) => {
        return api.post(url, data);
    },
};

export default commonApi;
